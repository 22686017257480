import FAQ from "../components/FAQ";
import HowItWork from "../components/HowItWork";
import MyTickets from "../components/MyTickets";
import PoolsResults from "../components/PoolsResults";
import SubscribeUs from "../components/SubscribeUs";

const Home = () => {
  return (
    <div className="page_wrapper">
      <HowItWork />
      <PoolsResults />
      <MyTickets />
      <FAQ />
      <SubscribeUs />
    </div>
  );
};

export default Home;
