import { Link } from "react-scroll";
import { useState } from "react";
import mainLogo from "../../assets/img/logo.svg";
import {
  GithubIcon,
  TwitterIcon,
  EthereumIcon,
  DollerIcon,
  DiscordIcon,
  ChainLinkIcon,
} from "../SVG";
import ModalPopup from "../Modal";
import useTranslations from "../../services/i18n/useTranslations";

const Footer = () => {
  const { t } = useTranslations();
  const [show, setShow] = useState(false);

  const ModalContent = () => (
    <div className="tickets">
      <ul>
        <li>
          <div className="ticket_icon">
            <h5>{t.pq1}</h5>
            <p>
              {t.pa1}{" "}
              <a
                style={{ color: "white" }}
                href="https://saturna.gitbook.io/saturna/"
                target="_blank"
              >
                {t.here}
              </a>
            </p>

            <h5 style={{ marginTop: "10px" }}>{t.pq2}</h5>
            <p>
              {t.pa2}{" "}
              <a
                style={{ color: "white" }}
                href="https://saturna.gitbook.io/saturna/"
                target="_blank"
              >
                {t.here}
              </a>
            </p>

            <h5 style={{ marginTop: "10px" }}>{t.pq3} </h5>
            <p>
              {t.pa3}{" "}
              <a
                style={{ color: "white" }}
                href="https://saturna.gitbook.io/saturna/"
                target="_blank"
              >
                {t.here}
              </a>
            </p>
          </div>
        </li>
      </ul>
    </div>
  );

  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <Link to="/">
              <img src={mainLogo} />
            </Link>
          </div>
          <div className="col-lg-5">
            <div className="d-flex footer_menu">
              <div>
                <h3>{t.quick_links}</h3>
                <ul>
                  <li>
                    <a
                      href="https://saturna.gitbook.io/saturna/"
                      target="_blank"
                    >
                      {t.about}
                    </a>
                  </li>
                  <li>
                    <Link style={{ cursor: "pointer" }} to="pool">
                      {t.Results}
                    </Link>
                  </li>
                  <li>
                    <Link style={{ cursor: "pointer" }} to="ticket">
                      {t.My_Tickets}
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        setShow(true);
                      }}
                      style={{ cursor: "pointer" }}
                      to="/"
                    >
                      {t.Provably_Fair}
                    </Link>
                  </li>
                  <li>
                    <a
                      href="https://discord.com/invite/RVzjJPkG8Z"
                      target="_blank"
                    >
                      Discord
                    </a>
                  </li>
                </ul>
              </div>

              <div>
                <h3>{t.resources}</h3>
                <ul>
                  <li>
                    <a
                      href="https://saturna.gitbook.io/saturna/"
                      target="_blank"
                    >
                      Saturna Litepaper
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://saturna.gitbook.io/saturna/"
                      target="_blank"
                    >
                      {t.blockhain_explorer}
                    </a>
                  </li>
                  <li>
                    <Link style={{ cursor: "pointer" }} to="faq">
                      FAQ
                    </Link>
                  </li>
                  <li>
                    <a
                      href="https://saturna.gitbook.io/saturna/"
                      target="_blank"
                    >
                      {t.developers}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://saturna.gitbook.io/saturna/"
                      target="_blank"
                    >
                      {t.security}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div>
              <h2>Powered by</h2>

              <div className="pay_options">
                {/* <span><VisaIcon /></span>
                <span><MastercardIcon /></span> */}
                <span>
                  <DollerIcon />
                </span>
                <span>
                  <EthereumIcon />
                </span>
                <span>
                  <ChainLinkIcon />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cm_copyright">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 order-lg-2 social_icons">
              <p>{t.community}</p>
              <div>
                <a href="https://twitter.com/Saturnaxyz" target="_blank">
                  <TwitterIcon />
                </a>
                <a href="https://gitlab.com/saturna-xyz" target="_blank">
                  <GithubIcon />
                </a>
                <a href="https://discord.com/invite/RVzjJPkG8Z" target="_blank">
                  <DiscordIcon />
                </a>
              </div>
            </div>
            <div className="col-lg-6 order-lg-1">
              <p>©2022 SATURNA. All rights reserved</p>
            </div>
          </div>
        </div>
      </div>

      <ModalPopup
        modalShow={show}
        handleClose={() => {
          setShow(false);
        }}
      >
        <ModalContent />
      </ModalPopup>
    </footer>
  );
};

export default Footer;
