import {
  ticket,
  rng,
  saturnaLotteryHubProxy,
  saturnaLotteryHubImplementation,
  usdc,
} from "../config";

export const ticketAddress = ticket;
export const rngChainlinkV2Address = rng;
export const saturnaLotteryProxyHubAddress = saturnaLotteryHubProxy;
export const saturnaLotteryHubImplementationAddress =
  saturnaLotteryHubImplementation;
export const usdcAddress = usdc;
