import { Dropdown } from "react-bootstrap";
import { Link } from "react-scroll";
import { webUrl } from "../../config";
import { useEthers } from "@usedapp/core";
import mainLogo from "../../assets/img/logo.svg";
import ModalPopup from "../Modal";
import { MenuIcon } from "../SVG";
import useTranslations from "../../services/i18n/useTranslations";
import { useState } from "react";

const Header = () => {
  const [show, setShow] = useState(false);
  const { account, activateBrowserWallet, deactivate } = useEthers();
  const { lang, setLang, t } = useTranslations();
  console.log(lang);

  const toggleMenuClass = () => {
    document.body.classList.toggle("menu_open");
  };

  const ModalContent = () => (
    <div className="tickets">
      <ul>
        <li>
          <div className="ticket_icon">
            <h5>{t.pq1}</h5>
            <p>
              {t.pa1}{" "}
              <a
                style={{ color: "white" }}
                href="https://saturna.gitbook.io/saturna/"
                target="_blank"
              >
                {t.here}
              </a>
            </p>

            <h5 style={{ marginTop: "10px" }}>{t.pq2}</h5>
            <p>
              {t.pa2}{" "}
              <a
                style={{ color: "white" }}
                href="https://saturna.gitbook.io/saturna/"
                target="_blank"
              >
                {t.here}
              </a>
            </p>

            <h5 style={{ marginTop: "10px" }}>{t.pq3} </h5>
            <p>
              {t.pa3}{" "}
              <a
                style={{ color: "white" }}
                href="https://saturna.gitbook.io/saturna/"
                target="_blank"
              >
                {t.here}
              </a>
            </p>
          </div>
        </li>
      </ul>
    </div>
  );

  return (
    <header>
      <div className="container header_container">
        <div className="main_logo">
          <Link to="/">
            <img src={mainLogo} />
          </Link>
        </div>

        <div className="menu_primary">
          <ul>
            <li>
              <Link
                activeClass="active"
                // to="main"
                to="pool"
                spy={true}
                //smooth={true}
                offset={-100}
                duration={500}
                onClick={toggleMenuClass}
              >
                {t.Results}
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                // to="pool"
                to="main"
                spy={true}
                //smooth={true}
                offset={-100}
                duration={500}
                onClick={() => {
                  setShow(true);
                }}
              >
                {t.Provably_Fair}
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="faq"
                spy={true}
                //smooth={true}
                offset={-100}
                duration={500}
                onClick={toggleMenuClass}
              >
                {t.FAQ}
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="ticket"
                spy={true}
                //smooth={true}
                offset={-100}
                duration={500}
                onClick={toggleMenuClass}
              >
                {t.My_Tickets}
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="suscribe"
                spy={true}
                //smooth={true}
                offset={-100}
                duration={300}
                onClick={toggleMenuClass}
              >
                {t.community}
              </Link>
            </li>
            <li className="lang_dropdown hide_mobile">
              <Dropdown>
                <Dropdown.Toggle style={{ background: "none" }}>
                  {lang == "es" ? "ES" : "EN"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {lang == "es" ? (
                    <Dropdown.Item
                      style={{ textDecoration: "none" }}
                      onClick={() => setLang("en")}
                    >
                      EN
                    </Dropdown.Item>
                  ) : null}
                  {lang == "en" ? (
                    <Dropdown.Item
                      style={{ textDecoration: "none" }}
                      onClick={() => setLang("es")}
                    >
                      ES
                    </Dropdown.Item>
                  ) : null}
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="header_btn">
              {account ? (
                <Link
                  to="/"
                  onClick={() => deactivate()}
                  className="header_btn"
                >
                  Disconnect
                </Link>
              ) : (
                <>
                  {window.ethereum ? (
                    <Link
                      style={{ textDecoration: "none", cursor: "pointer" }}
                      to="/"
                      onClick={() => {
                        activateBrowserWallet();
                      }}
                      className="header_btn"
                    >
                      {t.Connect}
                    </Link>
                  ) : (
                    <a
                      href={webUrl}
                      style={{ textDecoration: "none" }}
                      className="header_btn"
                    >
                      {t.Connect}
                    </a>
                  )}
                </>
              )}
            </li>
          </ul>
        </div>

        <div className="lang_dropdown header_btn show_mobile">
          {account ? (
            <Link
              style={{ textDecoration: "none", cursor: "pointer" }}
              to="/"
              onClick={() => {
                deactivate();
              }}
              className="header_btn"
            >
              {t.disconnect}
            </Link>
          ) : (
            <>
              {window.ethereum ? (
                <Link
                  style={{ textDecoration: "none", cursor: "pointer" }}
                  to="/"
                  onClick={() => {
                    activateBrowserWallet();
                  }}
                  className="header_btn"
                >
                  {t.Connect}
                </Link>
              ) : (
                <a
                  href={webUrl}
                  style={{ textDecoration: "none" }}
                  className="header_btn"
                >
                  {t.Connect}
                </a>
              )}
            </>
          )}
        </div>

        <div className="lang_dropdown show_mobile">
          <Dropdown>
            <Dropdown.Toggle style={{ background: "none" }}>
              {lang == "es" ? "ES" : "EN"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {lang == "es" ? (
                <Dropdown.Item onClick={() => setLang("en")}>EN</Dropdown.Item>
              ) : null}
              {lang == "en" ? (
                <Dropdown.Item onClick={() => setLang("es")}>ES</Dropdown.Item>
              ) : null}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="menu_icon" onClick={toggleMenuClass}>
          <MenuIcon />
        </div>
      </div>

      <ModalPopup
        modalShow={show}
        handleClose={() => {
          setShow(false);
        }}
      >
        <ModalContent />
      </ModalPopup>
    </header>
  );
};

export default Header;
