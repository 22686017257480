import { useTokenBalance, useTokenAllowance } from "@usedapp/core";
import { toast } from "react-toastify";
import WalletConnectProvider from "@walletconnect/web3-provider";
import dayjs from "dayjs";
import { saturnaLotteryProxyHubAddress, usdcAddress } from "../contracts";
import SaturnaLotteryHubABI from "../contracts/abi/contracts/SaturnaLotteryHub.sol/SaturnaLotteryHub.json";
import USDCABI from "../contracts/abi/USDC.json";
import { ethers } from "ethers";
import { formatUnits } from "ethers/lib/utils";
import { useState, useEffect } from "react";
import { graphqlEndPoint, infuraId, supportedChainId } from "../config";
import axios from "axios";
import { useGetEntryFee, useGetIsEntered, useGetLotteryId } from "../hooks";
import { useEthers } from "@usedapp/core";
import useTraslations from "../services/i18n/useTranslations";

const HowItWork = () => {
  const { account, chainId, switchNetwork } = useEthers();

  const [time, setTime] = useState({});
  const [lotteryTimer, setLotteryTimer] = useState(0);
  const [currentTime, setCurrentTime] = useState(dayjs().unix());
  const [progress, setProgress] = useState(0);
  const { t } = useTraslations();
  const lotteryId = useGetLotteryId();
  const lotteryID = lotteryId ? lotteryId : 0;
  const isEntered = useGetIsEntered(lotteryID, account);
  const entered = isEntered ? isEntered : false;
  const [approveWaiting, setApproveWaiting] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const tokenAllowance = useTokenAllowance(
    usdcAddress,
    account,
    saturnaLotteryProxyHubAddress
  );

  const entryFee = useGetEntryFee();

  const endTime = time ? time.endTime : 0;
  const timeLeft = Number(endTime) - currentTime;

  const getLotteryTimer = () => {
    if (timeLeft <= 0) {
      setLotteryTimer(0);
    } else {
      setLotteryTimer(timeLeft);
    }
  };

  useEffect(() => {
    if (chainId !== supportedChainId) {
      switchNetwork(supportedChainId);
    }
    if (tokenAllowance >= entryFee) {
      setProgress(1);
    }
    getTime();
    getLotteryTimer();

    const timer = setTimeout(() => {
      setCurrentTime(dayjs().unix());
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [progress, currentTime]);

  const userBalance = useTokenBalance(usdcAddress, account);
  const formattedUserBalance = userBalance ? userBalance : 0;

  const prizePool = useTokenBalance(usdcAddress, saturnaLotteryProxyHubAddress);
  const formattedPrizePool = prizePool
    ? parseFloat(formatUnits(prizePool, 6))
    : 0;

  const formattedEntryFee = entryFee ? parseFloat(formatUnits(entryFee, 6)) : 0;

  const USDCInterface = new ethers.utils.Interface(USDCABI);
  const SaturnaLotteryHubInterface = new ethers.utils.Interface(
    SaturnaLotteryHubABI
  );

  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const notifySuccess = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const notifyError = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const notifyWaiting = (msg) => {
    toast.info(msg, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const getTime = async () => {
    const endPoint = graphqlEndPoint;

    try {
      const result = await axios.post(endPoint, {
        query: `
        {
          lotteryCreateds(orderBy: startTime, orderDirection: desc, first: 1){
           id
           lotteryId
           startTime
           endTime
         }
        }
        `,
      });

      setTime(result.data.data.lotteryCreateds[0]);
    } catch (error) {}
  };

  const approveUSDC = async () => {
    if (chainId !== supportedChainId) {
      await switchNetwork(supportedChainId);
    }

    try {
      if (window.ethereum != undefined) {
        setApproveWaiting(true);
        let mainProvider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = mainProvider.getSigner();
        if (tokenAllowance < entryFee) {
          let usdcContract = new ethers.Contract(
            usdcAddress,
            USDCInterface,
            signer
          );
          let approveTx = await usdcContract.approve(
            saturnaLotteryProxyHubAddress,
            entryFee.toString()
          );
          notifyWaiting(t.wait_confirm);

          await approveTx.wait();
          setProgress(1);
          await setApproveWaiting(false);
          notifySuccess(t.token_allowed);
        }
      } else {
        setApproveWaiting(true);
        const walletConnectProvider = new WalletConnectProvider({
          infuraId: infuraId,
          qrcode: true,
        });

        await walletConnectProvider.enable();

        const provider = new ethers.providers.Web3Provider(
          walletConnectProvider
        );

        const signer = provider.getSigner();

        if (tokenAllowance < entryFee) {
          let usdcContract = new ethers.Contract(
            usdcAddress,
            USDCInterface,
            signer
          );
          let approveTx = await usdcContract.approve(
            saturnaLotteryProxyHubAddress,
            entryFee.toString()
          );
          notifyWaiting(t.wait_confirm);

          await approveTx.wait();
          await setApproveWaiting(false);
          notifySuccess(t.token_allowed);
        }
      }

      setApproveWaiting(false);
    } catch (error) {
      notifyError(error);
      setApproveWaiting(false);
    }
  };

  const enterLottery = async () => {
    if (chainId !== supportedChainId) {
      await switchNetwork(supportedChainId);
    }

    try {
      setWaiting(true);
      if (window.ethereum != undefined) {
        let mainProvider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = mainProvider.getSigner();
        let saturnaLotteryHubContract = new ethers.Contract(
          saturnaLotteryProxyHubAddress,
          SaturnaLotteryHubInterface,
          signer
        );
        let enterLotteryTx = await saturnaLotteryHubContract.enterLottery();

        notifyWaiting(t.wait_confirm);

        await enterLotteryTx.wait();
        await setWaiting(false);
        notifySuccess(t.success_entry);
      } else {
        setWaiting(true);

        const walletConnectProvider = new WalletConnectProvider({
          infuraId: infuraId,
          qrcode: true,
        });

        await walletConnectProvider.enable();

        const provider = new ethers.providers.Web3Provider(
          walletConnectProvider
        );

        const signer = provider.getSigner();

        let saturnaLotteryHubContract = new ethers.Contract(
          saturnaLotteryProxyHubAddress,
          SaturnaLotteryHubInterface,
          signer
        );
        let enterLotteryTx = await saturnaLotteryHubContract.enterLottery();

        notifyWaiting(t.wait_confirm);

        await enterLotteryTx.wait();
        await setWaiting(false);
        notifySuccess(t.success_entry);
      }

      setWaiting(false);
    } catch (error) {
      notifyError(t.failed);
      setWaiting(false);
    }
  };

  const flooredDays = Math.floor(lotteryTimer / 86400) % 60;
  const formmatedFlooredDays = flooredDays.toString();

  const flooredHours = Math.floor(lotteryTimer / 3600) % 24;
  const formattedFlooredHours = flooredHours.toString();

  const flooredMinutes = Math.floor(lotteryTimer / 60) % 60;
  const formattedFlooredMinutes = flooredMinutes.toString();

  const flooredSeconds = Math.floor(lotteryTimer) % 60;
  const formattedFlooredSeconds = flooredSeconds.toString();

  return (
    <div className="main_banner" id="main">
      <div className="pink_blur"></div>
      <div className="blur_line1"></div>
      <div className="blur_line2"></div>
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-lg-6 order-lg-2">
            <div className="timer_card">
              <h5>{t.Saturna_Pool_USDC}</h5>
              <h2>{formatter.format(formattedPrizePool)}</h2>

              <h4>{t.Next_Round}</h4>

              <div className="timer">
                <div className="time_box">
                  {formmatedFlooredDays.length == 2 ? (
                    <>
                      <span>{formmatedFlooredDays.slice(0)}</span>
                      <span>{formmatedFlooredDays.slice(-1)}</span>
                    </>
                  ) : (
                    <>
                      <span>0</span>
                      <span>{formmatedFlooredDays}</span>
                    </>
                  )}
                  <p>{t.DAY}</p>
                </div>
                <div className="time_box">
                  {formattedFlooredHours.length == 2 ? (
                    <>
                      <span>{formattedFlooredHours.slice(0, 1)}</span>
                      <span>{formattedFlooredHours.slice(-1)}</span>
                    </>
                  ) : (
                    <>
                      <span>0</span>
                      <span>{formattedFlooredHours}</span>
                    </>
                  )}
                  <p>{t.HR}</p>
                </div>
                <div className="time_box">
                  {formattedFlooredMinutes.length == 2 ? (
                    <>
                      <span>{formattedFlooredMinutes.slice(0, 1)}</span>
                      <span>{formattedFlooredMinutes.slice(-1)}</span>
                    </>
                  ) : (
                    <>
                      <span>0</span>
                      <span>{formattedFlooredMinutes}</span>
                    </>
                  )}
                  <p>{t.MIN}</p>
                </div>
                <div className="time_box">
                  {formattedFlooredSeconds.length == 2 ? (
                    <>
                      <span>{formattedFlooredSeconds.slice(0, 1)}</span>
                      <span>{formattedFlooredSeconds.slice(-1)}</span>
                    </>
                  ) : (
                    <>
                      <span>0</span>
                      <span>{formattedFlooredSeconds}</span>
                    </>
                  )}
                  <p>{t.SEC}</p>
                </div>
              </div>

              {account ? (
                <>
                  {formattedUserBalance < entryFee && lotteryTimer == 0 ? (
                    <button className="btn btn-primary mt-4 dias disabled">
                      {t.enter} {formattedEntryFee} USDC
                    </button>
                  ) : (
                    <>
                      {entered ? (
                        <button className="btn btn-primary mt-4 dias disabled">
                          {t.enter} {formattedEntryFee} USDC
                        </button>
                      ) : (
                        <>
                          <section className="step-wizard">
                            <ul className="step-wizard-list">
                              <li
                                style={{ marginLeft: "-45px" }}
                                className="step-wizard-item"
                              >
                                <span className="progress-count">1</span>
                                <span className="progress-label">
                                  {t.Connect}
                                </span>
                              </li>
                              <li
                                className={`step-wizard-item ${
                                  progress == 1 ? null : "current-item"
                                }`}
                              >
                                <span className="progress-count">2</span>
                                <span className="progress-label">
                                  {t.approve}
                                </span>
                              </li>
                              <li
                                className={`step-wizard-item ${
                                  progress == 1 ? "current-item" : null
                                }`}
                              >
                                <span className="progress-count">3</span>
                                <span className="progress-label">
                                  {t.enter}
                                </span>
                              </li>
                            </ul>
                          </section>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginLeft: "60px",
                              marginRight: "60px",
                            }}
                          >
                            {tokenAllowance >= entryFee ? (
                              <button
                                onClick={waiting ? null : () => enterLottery()}
                                className={`btn btn-primary mt-4 dias ${
                                  waiting ? "disabled" : null
                                }`}
                              >
                                {t.enter} {t.game}
                              </button>
                            ) : (
                              <button
                                onClick={
                                  approveWaiting
                                    ? null
                                    : () => {
                                        approveUSDC();
                                      }
                                }
                                className={`btn btn-primary mt-4 dias ${
                                  approveWaiting ? "disabled" : null
                                }`}
                              >
                                {t.approve} {formattedEntryFee} USDC
                              </button>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {entered ? (
                    <button className="btn btn-primary mt-4 dias disabled">
                      {t.enter} {formattedEntryFee} USDC
                    </button>
                  ) : (
                    <button className="btn btn-primary mt-4 dias disabled">
                      {t.enter} {formattedEntryFee} USDC
                    </button>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="col-lg-6 order-lg-1 mob_view">
            <button className="btn btn-light btn-sm no_hover">
              {t.Lets_Start}
            </button>
            <h1>{t.HOW_IT_WORKS}</h1>
            <div className="main_banner_content">
              <p>
                <b>1</b>
                {t.how_it_work_first}
              </p>
              <p>
                <b>2</b>
                {t.how_it_work_second}
              </p>
              <p>
                <b>3</b>
                {t.how_it_work_third}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWork;
