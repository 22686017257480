import { DollerIcon } from "./SVG";
import ModalPopup from "./Modal";
import { useState, useEffect } from "react";
import { useEthers } from "@usedapp/core";
import moment from "moment";
import useTranslations from "../services/i18n/useTranslations";
import axios from "axios";
import { FiExternalLink } from "react-icons/fi";
import { graphqlEndPoint, etherscan } from "../config";

const MyTickets = () => {
  const [show, setShow] = useState(false);
  const [tickets, setTickets] = useState([]);
  const { t } = useTranslations();

  const { account } = useEthers();

  const userAccount = account
    ? account
    : "0x0000000000000000000000000000000000000000";

  useEffect(() => {
    getMyTickets();
  }, [show, tickets]);

  const getMyTickets = async () => {
    const endPoint = graphqlEndPoint;

    try {
      if (show == true) {
        const result = await axios.post(endPoint, {
          query: `
              {
                lotteryEntereds(orderBy: time, orderDirection: desc, where:{user:"${userAccount.toLocaleLowerCase()}"}){
                  id
                  user
                  time
                }
              }
              `,
        });

        setTickets(result.data.data.lotteryEntereds);
      } else {
        const result = await axios.post(endPoint, {
          query: `
              {
                lotteryEntereds(orderBy:time, orderDirection: desc, where:{user:"${userAccount.toLocaleLowerCase()}"}, first:3){
                  id
                  user
                  time
                }
              }
              `,
        });

        setTickets(result.data.data.lotteryEntereds);
      }
    } catch (error) {}
  };

  const ModalContent = () => (
    <div className="tickets">
      {tickets.length == 0 ? (
        <ul>
          <p>{t.ticket_not_found}</p>
        </ul>
      ) : (
        <ul>
          {tickets.map((ticket, i) => {
            return (
              <li key={i}>
                <div className="ticket_icon">
                  <span className="ticket_icon_box">
                    <DollerIcon />
                  </span>{" "}
                  {moment.unix(ticket.time).format("MM - DD - YYYY - HH:mm:ss")}
                </div>
                <div className="angle_up">
                  <a href={etherscan} target="blank">
                    <FiExternalLink
                      style={{
                        color: "white",
                        fontSize: "20px",
                        marginBottom: "7px",
                        cursor: "pointer",
                      }}
                    />
                  </a>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
  return (
    <>
      <div className="cm_section" id="ticket">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-6 mob_text_center">
              <h3>{t.my_tickets}</h3>
              <p>{t.check_your_result}</p>
              <button
                onClick={() => {
                  setShow(true);
                }}
                className="btn btn-primary hide_on_mobile"
              >
                {t.see_all}
              </button>
            </div>
            <div className="col-lg-6">
              <ModalContent />

              <div className="d-flex justify-content-center mt-4 show_mobile">
                <button
                  onClick={() => {
                    setShow(true);
                  }}
                  className="btn btn-primary"
                >
                  {t.see_all}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPopup
        modalShow={show}
        handleClose={() => {
          setShow(false);
        }}
      >
        <ModalContent />
      </ModalPopup>
    </>
  );
};

export default MyTickets;
