import { saturnaLotteryProxyHubAddress } from "../contracts";
import saturnaLotteryHubProxyABI from "../contracts/abi/contracts/SaturnaLotteryHub.sol/SaturnaLotteryHub.json";
import { ethers } from "ethers";
import { useCall } from "@usedapp/core";

export function useGetEntryFee() {
  const { value, error } =
    useCall({
      contract: new ethers.Contract(
        saturnaLotteryProxyHubAddress,
        saturnaLotteryHubProxyABI
      ),
      method: "getEntryFee",
      args: [],
    }) ?? {};
  if (error) {
    console.error(error.message);
    return undefined;
  }
  return value?.[0];
}

export function useGetCurrentLottery() {
  const { value, error } =
    useCall({
      contract: new ethers.Contract(
        saturnaLotteryProxyHubAddress,
        saturnaLotteryHubProxyABI
      ),
      method: "s_lottery",
      args: [],
    }) ?? {};
  if (error) {
    console.error(error.message);
    return undefined;
  }
  return value?.[0];
}

export function useGetStartTime() {
  const { value, error } =
    useCall({
      contract: new ethers.Contract(
        saturnaLotteryProxyHubAddress,
        saturnaLotteryHubProxyABI
      ),
      method: "getCurrentLotteryStartTime",
      args: [],
    }) ?? {};
  if (error) {
    console.error(error.message);
    return undefined;
  }
  return value?.[0];
}

export function useGetEndTime() {
  const { value, error } =
    useCall({
      contract: new ethers.Contract(
        saturnaLotteryProxyHubAddress,
        saturnaLotteryHubProxyABI
      ),
      method: "getCurrentLotteryEndTime",
      args: [],
    }) ?? {};
  if (error) {
    console.error(error.message);
    return undefined;
  }
  return value?.[0];
}

export function useGetLotteryId() {
  const { value, error } =
    useCall({
      contract: new ethers.Contract(
        saturnaLotteryProxyHubAddress,
        saturnaLotteryHubProxyABI
      ),
      method: "getCurrentLotteryId",
      args: [],
    }) ?? {};
  if (error) {
    console.error(error.message);
    return undefined;
  }
  return value?.[0];
}

export function useGetIsEntered(lotteryId, user) {
  const { value, error } =
    useCall({
      contract: new ethers.Contract(
        saturnaLotteryProxyHubAddress,
        saturnaLotteryHubProxyABI
      ),
      method: "s_hasEntered",
      args: [lotteryId, user],
    }) ?? {};
  if (error) {
    console.error(error.message);
    return undefined;
  }
  return value?.[0];
}
