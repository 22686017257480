import { CalenderIcon, DollerIcon, WinIcon } from "./SVG";
import { useState, useEffect } from "react";
import ModalPopup from "./Modal";
import momemt from "moment";
import axios from "axios";
import useTranslations from "../services/i18n/useTranslations";
import { formatUnits } from "ethers/lib/utils";
import { graphqlEndPoint, etherscan } from "../config";

const PoolsResults = () => {
  const [show, setShow] = useState(false);
  const [winningResults, setWinningResults] = useState([]);
  const { t } = useTranslations();

  useEffect(() => {
    getResults();
  }, [show]);

  const getResults = async () => {
    const endPoint = graphqlEndPoint;

    try {
      if (show == true) {
        const result = await axios.post(endPoint, {
          query: `
            {
              winnerDeclaredAndPaids(orderBy: time, orderDirection: desc){
                id
                winner
                pool
                time
              }
            }
            `,
        });

        setWinningResults(result.data.data.winnerDeclaredAndPaids);
      } else {
        const result = await axios.post(endPoint, {
          query: `
            {
              winnerDeclaredAndPaids(orderBy: time, orderDirection: desc, first:3){
                id
                winner
                pool
                time
              }
            }
            `,
        });

        setWinningResults(result.data.data.winnerDeclaredAndPaids);
      }
    } catch (error) {}
  };

  const MainItem = () => (
    <div className="row">
      <div className="col-lg-4">
        <div className="pool_card">
          <div className="pool_icon">
            <CalenderIcon />
          </div>
          <h3>{t.date}</h3>
          {winningResults.map((winningResult, i) => (
            <div key={i}>
              <p>{momemt.unix(winningResult.time).format("MM - DD - YYYY")}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="col-lg-4">
        <div className="pool_card">
          <div className="pool_icon">
            <WinIcon />
          </div>
          <h3>{t.winning_address}</h3>
          {winningResults.map((winningResult, i) => (
            <div key={i}>
              <p>
                {winningResult.winner.slice(0, 6)}...
                {winningResult.winner.slice(-6)}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className="col-lg-4">
        <div className="pool_card">
          <div className="pool_icon">
            <DollerIcon />
          </div>
          <h3>Pool</h3>

          {winningResults.map((winningResult, i) => (
            <div key={i}>
              <p
                style={{
                  color: "white",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                <a style={{ color: "white" }} href={etherscan} target="_balnk">
                  {parseFloat(formatUnits(winningResult.pool, 6))} USDC
                </a>
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="cm_section cm_pool_section" id="pool">
        <div className="cm_pool_blur" />
        <div className="container">
          <h2 className="sec_title">{t.check_results}</h2>

          <MainItem />

          <div className="d-flex justify-content-center">
            <button
              onClick={() => {
                setShow(true);
              }}
              className="btn btn-primary"
            >
              {t.see_all}
            </button>
          </div>
        </div>
      </div>
      <ModalPopup
        modalShow={show}
        handleClose={() => {
          setShow(false);
        }}
      >
        <MainItem />
      </ModalPopup>
    </>
  );
};

export default PoolsResults;
