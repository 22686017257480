export const CalenderIcon = () => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none">
    <path
      d="M38.2949 3.85806H36.2811V3.25978C36.2811 1.46632 34.8205 0 33.0358 0H32.9878C31.2029 0 29.7425 1.46627 29.7425 3.25978V3.85806H11.7947V3.25978C11.7947 1.46632 10.3344 0 8.54943 0H8.50004C6.71516 0 5.25478 1.46627 5.25478 3.25978V3.85806H3.24531C1.46043 3.85806 0 5.32572 0 7.11674V38.2807C0 40.0742 1.46038 41.5405 3.24531 41.5405H38.2949C40.0798 41.5405 41.5401 40.0742 41.5401 38.2807V7.11674C41.5401 5.32572 40.0798 3.85806 38.2949 3.85806ZM31.0406 3.25978C31.0406 2.18192 31.9129 1.30402 32.9878 1.30402H33.0357C34.1092 1.30402 34.9829 2.18192 34.9829 3.25978V9.05799C34.9829 10.1358 34.1092 11.0138 33.0357 11.0138H32.9878C31.9129 11.0138 31.0406 10.1358 31.0406 9.05799V3.25978ZM6.5529 3.25978C6.5529 2.18192 7.42515 1.30402 8.50033 1.30402H8.54943C9.62164 1.30402 10.4966 2.18192 10.4966 3.25978V9.05799C10.4966 10.1358 9.62159 11.0138 8.54943 11.0138H8.50033C7.42515 11.0138 6.5529 10.1358 6.5529 9.05799V3.25978ZM38.2949 38.9342H3.24531C2.89331 38.9342 2.59625 38.6314 2.59625 38.2807V15.3675H38.944V38.2807C38.944 38.6313 38.6469 38.9342 38.2949 38.9342ZM7.36671 20.0343H13.3551V22.9813H7.36671V20.0343ZM17.8225 20.0343H23.7635V22.9813H17.8225V20.0343ZM28.2309 20.0343H34.0767V22.9813H28.2309V20.0343ZM7.36671 26.023H13.3551V28.9221H7.36671V26.023ZM17.8225 26.023H23.7635V28.9221H17.8225V26.023ZM28.2309 26.023H34.0767V28.9221H28.2309V26.023ZM7.36671 31.8689H13.3551V34.9105H7.36671V31.8689ZM17.8225 31.8689H23.7635V34.9105H17.8225V31.8689ZM28.2309 31.8689H34.0767V34.9105H28.2309V31.8689Z"
      fill="white"
    />
  </svg>
);

export const WinIcon = () => (
  <svg width="27" height="47" viewBox="0 0 27 47" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.447778 0.00790861H10.2047L18.9942 15.9931C19.1825 16.332 19.4571 16.599 19.77 16.7689H6.65001L0.447778 0V0.00790861ZM13.5 24.9818L15.7555 30.4873L21.6941 30.9351L17.1489 34.7915L18.5611 40.5682L13.5 37.4311L8.43887 40.5682L9.85112 34.7915L5.30178 30.9317L11.2404 30.4836L13.5 24.9818ZM13.5 19.2767C20.9563 19.2767 27 25.3207 27 32.7767C27 40.2326 20.9563 46.2771 13.5 46.2771C6.04369 46.2729 0 40.2288 0 32.7725C0 25.317 6.04369 19.2767 13.5 19.2767ZM14.6972 0.00790861H26.1146L21.5212 12.4271L14.6972 0.00790861Z"
      fill="white"
    />
  </svg>
);

export const DollerIcon = () => (
  <svg width="48" height="46" viewBox="0 0 48 46" fill="none">
    <path
      d="M32.6665 27.6107C32.6665 22.9481 29.869 21.3493 24.2738 20.6831C20.2775 20.1505 19.4787 19.0843 19.4787 17.2192C19.4787 15.3542 20.8113 14.1554 23.4751 14.1554C25.8726 14.1554 27.2064 14.9542 27.8714 16.953C27.9411 17.1457 28.0677 17.3126 28.2345 17.4316C28.4013 17.5506 28.6003 17.6161 28.8052 17.6192H30.9365C31.0598 17.6222 31.1824 17.6001 31.2968 17.5542C31.4113 17.5084 31.5153 17.4398 31.6025 17.3526C31.6896 17.2655 31.7582 17.1615 31.804 17.047C31.8499 16.9325 31.872 16.81 31.869 16.6867V16.5529C31.6111 15.1093 30.8836 13.7912 29.7994 12.8036C28.7153 11.816 27.3353 11.2141 25.8739 11.0916V7.89398C25.8739 7.36147 25.4739 6.96146 24.8076 6.8277H22.8088C22.275 6.8277 21.8763 7.22771 21.7425 7.89398V10.9578C17.7462 11.4903 15.2149 14.1554 15.2149 17.4855C15.2149 21.8818 17.8787 23.6144 23.4738 24.2806C27.2052 24.9457 28.4039 25.7457 28.4039 27.877C28.4039 30.0083 26.5389 31.4746 24.0076 31.4746C20.5437 31.4746 19.3387 30.0083 18.945 28.0107C18.8112 27.4782 18.4124 27.2107 18.0124 27.2107H15.7499C15.6266 27.2078 15.504 27.2299 15.3896 27.2757C15.2751 27.3215 15.1711 27.3901 15.084 27.4773C14.9968 27.5645 14.9282 27.6685 14.8824 27.7829C14.8365 27.8974 14.8144 28.02 14.8174 28.1432V28.277C15.3499 31.6071 17.4824 34.0059 21.8839 34.6722V37.8685C21.8839 38.4023 22.2826 38.801 22.9489 38.9348H24.9477C25.4802 38.9348 25.8802 38.5348 26.0127 37.8685V34.6722C30.0103 34.0059 32.6741 31.2071 32.6741 27.6057L32.6665 27.6107Z"
      fill="white"
    />
    <path
      d="M17.0799 41.5985C6.68834 37.8684 1.35945 26.2781 5.21826 16.0204C7.2158 10.4252 11.6122 6.16137 17.0799 4.15878C17.6124 3.89253 17.8786 3.49251 17.8786 2.82624V0.966245C17.8786 0.433735 17.6124 0.0337218 17.0799 -0.100037C16.9461 -0.100037 16.6798 -0.100037 16.5461 0.0337215C3.88824 4.03007 -3.03691 17.4854 0.959439 30.142C2.12044 33.7978 4.14202 37.1215 6.85429 39.8338C9.56657 42.5461 12.8903 44.5676 16.5461 45.7286C17.0799 45.9949 17.6124 45.7286 17.7461 45.1961C17.8786 45.0624 17.8786 44.9299 17.8786 44.6636V42.7986C17.8786 42.3986 17.4799 41.866 17.0799 41.5985ZM31.2015 0.0387691C30.6677 -0.227486 30.1352 0.0387693 30.0027 0.571279C29.8689 0.705038 29.8689 0.837534 29.8689 1.10505V2.96505C29.8689 3.49756 30.2689 4.03007 30.6677 4.29633C41.0592 8.02642 46.3881 19.6167 42.5293 29.8757C40.5305 35.4709 36.1341 39.7335 30.6677 41.7373C30.1352 42.0036 29.8689 42.4036 29.8689 43.0699V44.9349C29.8689 45.4674 30.1352 45.8674 30.6677 45.9999C30.8015 45.9999 31.0677 45.9999 31.2015 45.8674C43.8568 41.866 50.7845 28.4094 46.7919 15.7541C45.6336 12.0818 43.6143 8.73956 40.9024 6.0059C38.1904 3.27224 34.8644 1.22636 31.2015 0.0387691Z"
      fill="white"
    />
  </svg>
);

export const TwitterIcon = () => (
  <svg width="38" height="31" viewBox="0 0 38 31" fill="none">
    <path
      d="M37.586 3.73315C36.1734 4.34598 34.6796 4.7511 33.1509 4.9359C34.7636 3.97242 35.9722 2.45669 36.5524 0.669888C35.0369 1.57207 33.3778 2.20771 31.6474 2.54919C30.4908 1.29489 28.9506 0.459963 27.2683 0.175352C25.586 -0.109259 23.8569 0.172546 22.352 0.97657C20.8472 1.78059 19.6517 3.06136 18.9532 4.61799C18.2547 6.17463 18.0926 7.91909 18.4923 9.57778C15.428 9.42278 12.4305 8.62488 9.69467 7.23591C6.95881 5.84693 4.54574 3.89795 2.61223 1.51557C1.93406 2.69985 1.57771 4.04102 1.57861 5.40573C1.57621 6.6731 1.88723 7.92139 2.48398 9.03948C3.08073 10.1576 3.94469 11.1107 4.99894 11.8141C3.7736 11.7808 2.57443 11.452 1.50344 10.8557V10.9497C1.51263 12.7254 2.13488 14.4435 3.26494 15.8132C4.395 17.183 5.96351 18.1204 7.70514 18.4669C7.03471 18.6709 6.33865 18.7785 5.63791 18.7864C5.15284 18.7807 4.66898 18.7367 4.19084 18.6548C4.68681 20.1823 5.64659 21.5173 6.93664 22.4738C8.22669 23.4304 9.78285 23.9611 11.3886 23.992C8.67712 26.1255 5.32948 27.2899 1.8793 27.2996C1.25112 27.3017 0.623431 27.264 0 27.1868C3.52262 29.4613 7.62773 30.6687 11.8208 30.6635C14.7144 30.6936 17.5849 30.1468 20.2647 29.055C22.9446 27.9633 25.38 26.3485 27.4288 24.3049C29.4776 22.2614 31.0986 19.8301 32.1972 17.1531C33.2959 14.476 33.8501 11.6069 33.8274 8.7133C33.8274 8.39382 33.8274 8.05554 33.8274 7.71727C35.3021 6.61752 36.574 5.26934 37.586 3.73315V3.73315Z"
      fill="white"
    />
  </svg>
);

export const GithubIcon = () => (
  <svg width="34" height="33" viewBox="0 0 34 33" fill="none">
    <path
      d="M12.3528 11.0065H22.2869C22.8619 11.0065 23.3724 10.6386 23.5542 10.0931L26.2172 2.10407C26.6135 0.915117 28.2814 0.8768 28.7316 2.04644C30.3007 6.1235 32.3662 11.4862 33.3977 14.151C34.0537 16.8844 32.671 20.01 31.7989 21.4031C31.723 21.5242 31.6251 21.6281 31.5124 21.716L18.1919 32.106C17.718 32.4757 17.057 32.4846 16.5755 32.1248C12.5714 29.1324 5.15634 23.5023 2.81089 21.5833C0.524026 19.1821 0.809884 15.6279 1.23867 14.151L5.78257 1.62755C6.21514 0.435347 7.9099 0.460442 8.30698 1.66493L11.0841 10.0889C11.2647 10.6366 11.7762 11.0065 12.3528 11.0065Z"
      fill="white"
    />
  </svg>
);

export const DiscordIcon = () => (
  <svg
    width="52"
    height="37"
    viewBox="0 0 52 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M42.543 27.6369C28.4792 36.1563 14.483 31.1867 9.24285 27.6369C9.24285 29.3949 13.8632 31.8628 16.1734 32.877C15.9029 33.6884 14.3703 35.6943 13.6378 36.5958C5.92979 35.6492 1.74893 31.6938 0.622002 29.8344C0.0810861 19.1513 4.56618 8.36676 6.87635 4.30988C12.4207 0.253025 17.4129 0.0276327 19.216 0.422043C13.4011 1.63912 9.46824 4.64794 8.22864 6.00022C22.4277 -1.30215 37.0212 2.95757 42.543 6.00022C40.1089 3.70133 34.7673 1.32356 32.4008 0.422043C37.4043 -1.06548 42.9374 2.39413 45.0785 4.30988C49.8116 11.3418 51.1075 24.2562 51.1639 29.8344C48.4593 34.4322 41.3597 36.2577 38.1481 36.5958L35.2744 32.877C40.1427 31.3895 42.1486 28.7638 42.543 27.6369ZM23.2751 20.8754C23.2751 23.6761 21.0047 25.9465 18.204 25.9465C15.4033 25.9465 13.1329 23.6761 13.1329 20.8754C13.1329 18.0747 15.4033 15.8043 18.204 15.8043C21.0047 15.8043 23.2751 18.0747 23.2751 20.8754ZM33.7568 25.9465C36.5575 25.9465 38.8279 23.6761 38.8279 20.8754C38.8279 18.0747 36.5575 15.8043 33.7568 15.8043C30.9561 15.8043 28.6857 18.0747 28.6857 20.8754C28.6857 23.6761 30.9561 25.9465 33.7568 25.9465Z"
      fill="white"
    />
  </svg>
);

export const TelegramIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      d="M21.7539 30.4166L31.1961 1.88468C31.5428 0.837005 30.5416 -0.15539 29.4971 0.200541L0.979056 9.91778C-0.140304 10.2992 -0.248576 11.8398 0.806431 12.374L12.5453 18.3183C12.7925 18.4434 12.9946 18.6423 13.1237 18.8874L19.3038 30.6195C19.8527 31.6617 21.3838 31.5349 21.7539 30.4166Z"
      fill="white"
    />
  </svg>
);

export const EthereumIcon = () => (
  <svg width="31" height="49" viewBox="0 0 31 49" fill="none">
    <path
      d="M15.339 36.2659L0.604492 27.5704L15.33 48.3289L30.072 27.5704L15.33 36.2659H15.339ZM15.558 0.327393L0.829492 24.7729L15.558 33.4834L30.2925 24.7819L15.558 0.327393Z"
      fill="white"
    />
  </svg>
);

export const VisaIcon = () => (
  <svg width="48" height="16" viewBox="0 0 48 16" fill="none">
    <path
      d="M34.8931 0.655015C33.7776 0.212896 32.5985 -0.0089622 31.4112 -0.000111481C27.5718 -0.000111481 24.8602 2.10361 24.841 5.12346C24.817 7.34202 26.7679 8.59225 28.2437 9.33351C29.7579 10.0956 30.2642 10.5759 30.2594 11.2571C30.2498 12.2933 29.05 12.7631 27.9341 12.7631C26.4008 12.7631 25.5633 12.5308 24.2747 11.9488L23.7972 11.7061L23.2525 15.1827C24.1859 15.6055 25.8681 15.9683 27.6054 15.9996C31.6872 15.9996 34.3556 13.909 34.3868 10.696C34.4204 8.92373 33.3645 7.58476 31.1425 6.47809C29.7915 5.76032 28.954 5.28006 28.954 4.55185C28.954 3.90717 29.6715 3.21811 31.1713 3.21811C32.1694 3.19184 33.1614 3.39553 34.082 3.81581L34.442 3.99069L34.9867 0.631525L34.8931 0.655015ZM44.8564 0.281776H41.8569C40.9234 0.281776 40.2203 0.561054 39.8124 1.56854L34.0436 15.783H38.1254L38.9413 13.4522L43.923 13.4574C44.0405 14.0029 44.4005 15.783 44.4005 15.783H47.9999L44.8564 0.281776ZM19.3194 0.151272H23.2093L20.776 15.6603H16.891L19.3194 0.146052V0.151272ZM9.44492 8.69926L9.84806 10.8526L13.6491 0.281776H17.7693L11.6478 15.7621H7.53721L4.17771 2.65433C4.1233 2.4382 3.99552 2.25257 3.82017 2.13493C2.60931 1.45458 1.32633 0.938281 -6.10352e-05 0.597593L0.0527309 0.271334H6.31339C7.16286 0.307875 7.84676 0.597594 8.07473 1.5842L9.44252 8.70709V8.69926H9.44492ZM40.0571 10.281L41.6073 5.94302C41.5881 5.99 41.9265 5.04777 42.1232 4.46572L42.3896 5.80469L43.2894 10.2784H40.0547V10.281H40.0571Z"
      fill="#E0E0E0"
    />
  </svg>
);

export const MastercardIcon = () => (
  <svg width="48" height="38" viewBox="0 0 48 38" fill="none">
    <path
      d="M17.4935 7.22742H30.4592V30.9537H17.4935V7.22742Z"
      fill="#BDBDBD"
    />
    <path
      d="M18.3167 19.091C18.3167 14.2703 20.5393 9.99445 23.9556 7.22771C21.4449 5.21562 18.2756 4 14.8181 4C6.62681 4 0 10.7489 0 19.091C0 27.4328 6.62681 34.1819 14.8179 34.1819C18.2754 34.1819 21.4447 32.9663 23.9556 30.954C20.5393 28.2293 18.3167 23.9116 18.3167 19.091Z"
      fill="#828282"
    />
    <path
      d="M47.9524 19.091C47.9524 27.4328 41.3256 34.1819 33.1345 34.1819C29.677 34.1819 26.5076 32.9663 23.9968 30.954C27.4543 28.1875 29.6359 23.9116 29.6359 19.091C29.6359 14.2703 27.4131 9.99445 23.9968 7.22771C26.5075 5.21562 29.677 4 33.1345 4C41.3256 4 47.9526 10.7909 47.9526 19.091H47.9524Z"
      fill="#E0E0E0"
    />
  </svg>
);

export const BitCoinIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
    <g clip-path="url(#clip0_0_591)">
      <path
        d="M39.4018 24.8367C36.7296 35.5567 25.8685 42.0732 15.1638 39.4009C4.44354 36.7287 -2.07302 25.8681 0.599319 15.1635C3.27151 4.44354 14.1168 -2.07302 24.8371 0.599321C35.5418 3.25588 42.0742 14.1165 39.4018 24.8367Z"
        fill="#E0E0E0"
      />
      <path
        d="M29.5231 17.5081C29.9138 14.8518 27.8981 13.4143 25.1169 12.4612L26.0231 8.85181L23.8356 8.30493L22.9606 11.8206C22.3825 11.6799 21.7888 11.5393 21.195 11.4143L22.07 7.88306L19.8825 7.33618L18.9919 10.9299C18.5075 10.8206 18.0388 10.7112 17.5856 10.6018V10.5862L14.5544 9.83618L13.9763 12.1799C13.9763 12.1799 15.6013 12.5549 15.57 12.5706C16.4606 12.7893 16.6169 13.3831 16.5856 13.8518L15.5544 17.9612C15.6169 17.9768 15.695 17.9924 15.7888 18.0393C15.7106 18.0237 15.6325 18.0081 15.5544 17.9768L14.1169 23.7268C14.0075 23.9924 13.7263 24.3987 13.1169 24.2424C13.1325 24.2737 11.5231 23.8518 11.5231 23.8518L10.4294 26.3674L13.2888 27.0862C13.82 27.2268 14.3356 27.3518 14.8513 27.4924L13.945 31.1331L16.1325 31.6799L17.0388 28.0706C17.6325 28.2268 18.2263 28.3831 18.7888 28.5237L17.8981 32.1174L20.0856 32.6643L20.9919 29.0237C24.7419 29.7268 27.5544 29.4456 28.7263 26.0549C29.6794 23.3362 28.6794 21.7581 26.7106 20.7268C28.1638 20.3987 29.2419 19.4456 29.5231 17.5081ZM24.5075 24.5393C23.8356 27.2581 19.2419 25.7893 17.7575 25.4143L18.9606 20.5862C20.445 20.9612 25.2263 21.6956 24.5075 24.5393ZM25.195 17.4612C24.57 19.9456 20.7575 18.6799 19.5231 18.3674L20.6169 13.9924C21.8513 14.3049 25.8356 14.8831 25.195 17.4612Z"
        fill="#0D0D2B"
      />
    </g>
    <defs>
      <clipPath id="clip0_0_591">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const ChainLinkIcon = () => (
  <svg viewBox="0 0 437.66 504.82">
    <g>
      <g>
        <path
          fill="#E0E0E0"
          d="M218.83,0,172.52,26.63,46.31,99.57,0,126.2V378.61l46.31,26.63,127.37,72.95L220,504.82l46.31-26.63,125.05-72.95,46.31-26.63V126.2L391.35,99.57,265.14,26.63ZM92.63,325.35V179.46l126.2-72.94L345,179.46V325.35L218.83,398.3Z"
        />
      </g>
    </g>
  </svg>
);

export const MenuIcon = () => (
  <svg width="25" height="17" viewBox="0 0 25 17">
    <rect x="0.174988" width="24" height="1.6" rx="0.8" fill="#D9D9D9" />
    <rect
      x="0.174988"
      y="7.6001"
      width="24"
      height="1.6"
      rx="0.8"
      fill="#D9D9D9"
    />
    <rect
      x="0.174988"
      y="15.2"
      width="24"
      height="1.6"
      rx="0.8"
      fill="#D9D9D9"
    />
  </svg>
);
