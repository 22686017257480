// FOR TESTNET

export const supportedChainId = 80001;
export const rpcUrl =
  "https://polygon-mumbai.infura.io/v3/d014af161a4b4ffbaa358366e232e2c8";
export const infuraId = "d014af161a4b4ffbaa358366e232e2c8";
export const graphqlEndPoint =
  "https://api.thegraph.com/subgraphs/name/haidaralimasu/saturna-mumbai";
export const etherscan =
  "https://mumbai.polygonscan.com/address/0xD508Ee028a4946b90DdB7a27eb79B7c467D973c9#events";

export const ticket = "0x2201541bFd0728A250f56471417827E61C3FE054";
export const rng = "0xb2248F5561415B6A2C2B12a5C20b89Ef4cdbEaDc";
export const saturnaLotteryHubProxy =
  "0xD508Ee028a4946b90DdB7a27eb79B7c467D973c9";
export const saturnaLotteryHubImplementation =
  "0x2673960A8b26af4BBFC9AbC1Dc60F4ca5077990c";
export const usdc = "0xe6b8a5CF854791412c1f6EFC7CAf629f5Df1c747";
export const webUrl = "dapp://testnet.saturna.xyz";

// FOR MAINNET

/*
export const supportedChainId = 137;
export const rpcUrl =
  "https://polygon-mainnet.infura.io/v3/d014af161a4b4ffbaa358366e232e2c8";
export const infuraId = "d014af161a4b4ffbaa358366e232e2c8";
export const graphqlEndPoint =
  "https://api.thegraph.com/subgraphs/name/saturnaxyz/saturna";
export const etherscan =
  "https://polygonscan.com/address/0x9142231e92ae2C40FF86986cFcc6ab019681A7c0#events";
export const ticket = "0x495550440F9ABBDFb8069a5e29215d8A5C14d961";
export const rng = "0x0f9beA4446182aE3b8b4054c0f3FeA74DdbB1107";
export const saturnaLotteryHubProxy =
  "0x9142231e92ae2C40FF86986cFcc6ab019681A7c0";
export const saturnaLotteryHubImplementation =
  "0x495550440F9ABBDFb8069a5e29215d8A5C14d961";
export const usdc = "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174";
export const webUrl = "dapp://saturna.xyz";
*/
