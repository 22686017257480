import subscribeBg1 from "../assets/img/subscribe_bg_1.png";
import subscribeBg2 from "../assets/img/subscribe_bg_2.png";
import useTranslations from "../services/i18n/useTranslations";

const SubscribeUs = () => {
  const { t } = useTranslations();

  return (
    <div className="cm_section subscribe_us" id="suscribe">
      <div className="container">
        <div className="subscribe_us_container">
          <img className="subscribe_bg_1" src={subscribeBg1} />
          <img className="subscribe_bg_2" src={subscribeBg2} />
          <div className="row d-flex align-items-center">
            <div className="col-lg-7">
              <h2>{t.join_discord}</h2>
              <p>{t.looking_help}</p>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-4">
              <form className="d-flex">
                {/* <input
                  placeholder="Enter your email"
                  type="email"
                  className="form-control"
                /> */}
                <a href="https://discord.com/invite/RVzjJPkG8Z" target="blank">
                  <a className="btn btn-light w-100 btn-lg">{t.get_invite}</a>
                </a>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscribeUs;
