import { Accordion } from "react-bootstrap";
import useTranslations from "../services/i18n/useTranslations";

const FAQ = () => {
  const { t } = useTranslations();

  const faqs = [
    { title: t.q0, content: t.a0 },
    {
      title: t.q1,
      content: t.a1,
    },
    {
      title: t.q2,
      content: t.a2,
    },
    {
      title: t.q3,
      content: t.a3,
    },
    {
      title: t.q4,
      content: t.a4,
    },
    {
      title: t.q5,
      content: t.a5,
    },
    {
      title: t.q6,
      content: t.a6,
    },
    {
      title: t.q7,
      content: t.a7,
    },
  ];

  return (
    <div className="cm_section cm_accordion" id="faq">
      <div className="container">
        <h2 className="sec_title">{t.faqs}</h2>
        <Accordion>
          {faqs.map((item, i) => (
            <Accordion.Item eventKey={i} key={i}>
              <Accordion.Header>{item.title}</Accordion.Header>
              <Accordion.Body>
                {item.content}{" "}
                <a
                  href="https://saturna.gitbook.io/saturna/frequently-asked-questions"
                  target="blank"
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "white",
                  }}
                >
                  {t.here}
                </a>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FAQ;
