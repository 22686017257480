import React from "react";
import Modal from "react-bootstrap/Modal";

export default function ModalPopup({ modalShow, handleClose, children }) {
  return (
    <Modal size="xl" show={modalShow} onHide={handleClose}>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
